import memoize from 'lodash/memoize'
import { reactive, toRefs } from 'vue'
import { useAirdropsStoreModule } from '@/store'

let store: ReturnType<typeof useAirdropsStoreModule>

const useAirdrops = () => {
  store = useAirdropsStoreModule()

  const stateRefs = toRefs(store.state)
  const gettersRefs = toRefs(store.getters)

  const $state = reactive({
    ...stateRefs,
    ...gettersRefs,
  })

  return {
    $store: store,
    $state,
    $actions: store.actions,
    $mutations: store.mutations,

    ...stateRefs,
    ...gettersRefs,
  }
}

export const useAirdropsStore = memoize(useAirdrops)
