import {
  GetTokensApis, INetworkConfig, NetworkChainId, TokenApi,
} from './types'
import {
  sepolia, bsc, mainnet, arbitrum, avalanche, polygon, base, optimism,
} from 'viem/chains'

// TODO: need inspect gasPrice settings for all settings

const ALCHEMY_KEY = process.env.VUE_APP_ALCHEMY_KEY

const ETHERSCAN_KEY = process.env.VUE_APP_ETHERSCAN_KEY
const ETHPLORER_KEY = process.env.VUE_APP_ETHPLORER_KEY
const DRPC_KEY = process.env.VUE_APP_DRPC_KEY

const ETHERSCAN_API_PARAMS = {
  type: GetTokensApis.ETHERSCAN,
  url: 'https://api.etherscan.io/v2/api',
  query: `apikey=${ETHERSCAN_KEY}&module=account&action=tokentx&address=`,
}

const MULTISENDER_API_PARAMS = {
  type: GetTokensApis.MULTISENDER,
  url: 'https://rpc.multisender.app/api/V9HSbkbGw9J8v64gDqoxauwhyCQjB8NF',
  query: '',
}

const ETHPLORER_API_PARAMS = {
  type: GetTokensApis.ETHPLORER,
  url: 'https://api.ethplorer.io/getAddressInfo',
  query: `apiKey=${ETHPLORER_KEY}`,
}

const ALCHEMY_API_PARAMS = (url: string): TokenApi => ({
  type: GetTokensApis.ALCHEMY,
  url: `https://${url}/v2/${ALCHEMY_KEY}`,
  query: '',
})

const BLOCKSCOUT_API_PARAMS = (url: string) => ({
  type: GetTokensApis.BLOCKSCOUT,
  url: `https://${url}/api`,
  query: 'module=account&action=tokenlist&address=',
})

export const NETWORK_MAINNET: INetworkConfig = {
  chain: mainnet,
  network: {
    chainId: NetworkChainId.mainnet,
    name: 'Ethereum Mainnet',
    nameShort: 'Mainnet',
    icon: 'symbols/ethereum',
    pollTime: 15,
    EIP1559: true,
    testnet: false,
    color: '#29b6af', // #627EEA
  },
  currency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    icon: 'symbols/ethereum',
  },
  contracts: {
    multisenderMerkle: {
      address: '0xA6646162Ab02E9945CeDdf950167F892F682c985',
      blockFrom: 14920219,
    },
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  rpc: {
    retryAttempt: 20,
    // url: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
    // url: 'https://lb.drpc.org/ogrpc?network=ethereum&dkey=ArwcutontEhPu970XT848odiuBT0l5MR76N9FhW5UfFk',
    url: `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`,
  },
  explorer: {
    url: 'https://etherscan.io',
  },
  tokenApis: [
    ETHPLORER_API_PARAMS,
    MULTISENDER_API_PARAMS,
    BLOCKSCOUT_API_PARAMS('eth.blockscout.com'),
    ALCHEMY_API_PARAMS('eth-mainnet.alchemyapi.io'),
    ETHERSCAN_API_PARAMS,
  ],
  blockGasLimit: 30_000_000,
}

export const NETWORK_SEPOLIA: INetworkConfig = {
  chain: sepolia,
  network: {
    chainId: NetworkChainId.sepolia,
    name: 'Ethereum Sepolia',
    nameShort: 'Sepolia',
    icon: 'symbols/sepolia',
    pollTime: 15,
    EIP1559: true,
    testnet: true,
    color: '#3099f2', // #627EEA
  },
  currency: {
    name: 'SEP',
    symbol: 'SEP',
    decimals: 18,
    icon: 'symbols/sepolia',
  },
  contracts: {
    multisenderMerkle: {
      address: '0xAa6E22C688Aafc52195E61555d0eDD65f2412332',
      blockFrom: 5293973,
    },
    multicall3: NETWORK_MAINNET.contracts.multicall3,
  },
  rpc: {
    retryAttempt: 15,
    // url: 'https://1rpc.io/sepolia',
    // url: 'https://lb.drpc.org/ogrpc?network=sepolia&dkey=ArwcutontEhPu970XT848odiuBT0l5MR76N9FhW5UfFk',
    url: `https://eth-sepolia.g.alchemy.com/v2/${ALCHEMY_KEY}`,
  },
  explorer: {
    url: 'https://sepolia.etherscan.io',
  },
  tokenApis: [
    BLOCKSCOUT_API_PARAMS('eth-sepolia.blockscout.com'),
    ALCHEMY_API_PARAMS('eth-sepolia.alchemyapi.io'),
    ETHERSCAN_API_PARAMS,
  ],
  blockGasLimit: 30_000_000,
}

export const NETWORK_BSC: INetworkConfig = {
  chain: bsc,
  network: {
    chainId: NetworkChainId.binance,
    nameShort: 'BSC',
    name: 'BNB Smart Chain Mainnet',
    pollTime: 15,
    EIP1559: false,
    icon: 'symbols/binance',
    testnet: false,
    color: '#F3BA2F',
  },
  currency: {
    name: 'BNB Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
    icon: 'symbols/binance',
  },
  contracts: {
    multisenderMerkle: {
      address: '0x00fe66030962387441a6Fdb32A593677752f71b7',
      blockFrom: 18477930,
    },
    multicall3: NETWORK_MAINNET.contracts.multicall3,
  },
  rpc: {
    retryAttempt: 15,
    mainUrl: 'https://bsc-dataseed1.bnbchain.org',
    url: `https://bnb-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`,
  },
  explorer: {
    url: 'https://bscscan.com',
  },
  tokenApis: [
    MULTISENDER_API_PARAMS,
    ALCHEMY_API_PARAMS('bnb-mainnet.alchemyapi.io'),
    ETHERSCAN_API_PARAMS,
  ],
  blockGasLimit: 30_000_000,
}

export const NETWORK_ARBITRUM: INetworkConfig = {
  chain: arbitrum,
  network: {
    chainId: NetworkChainId.arbitrum,
    name: 'Arbitrum One',
    nameShort: 'Arbitrum One',
    icon: 'symbols/arbitrum-one',
    pollTime: 15,
    EIP1559: true,
    testnet: false,
    color: '#3099f2',
  },
  currency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    icon: 'symbols/arbitrum-one',
  },
  contracts: {
    multisenderMerkle: {
      address: '0x25f1fdbD4e12C5FBDE4aB4A4F3BE92f19B13997c',
      blockFrom: 258600834,
    },
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  rpc: {
    retryAttempt: 15,
    url: `https://lb.drpc.org/ogrpc?network=arbitrum&dkey=${DRPC_KEY}`,
  },
  explorer: {
    url: 'https://arbiscan.io/',
  },
  tokenApis: [
    MULTISENDER_API_PARAMS,
    BLOCKSCOUT_API_PARAMS('arbitrum.blockscout.com'),
    ALCHEMY_API_PARAMS('arb-mainnet.g.alchemy.com'),
    ETHERSCAN_API_PARAMS,
  ],
  blockGasLimit: 30_000_000,
}

export const NETWORK_AVALANCHE: INetworkConfig = {
  chain: avalanche,
  network: {
    chainId: NetworkChainId.avalanche,
    name: 'Avalanche C-Chain',
    nameShort: 'Avalanche',
    icon: 'symbols/avalanche',
    pollTime: 15,
    EIP1559: true,
    testnet: false,
    color: '#3099f2',
  },
  currency: {
    name: 'AVAX',
    symbol: 'AVAX',
    decimals: 18,
    icon: 'symbols/avalanche',
  },
  contracts: {
    multisenderMerkle: {
      address: '0x25f1fdbD4e12C5FBDE4aB4A4F3BE92f19B13997c',
      blockFrom: 51147517,
    },
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  rpc: {
    retryAttempt: 15,
    url: `https://lb.drpc.org/ogrpc?network=avalanche&dkey=${DRPC_KEY}`,
  },
  explorer: {
    url: 'https://snowtrace.io/',
  },
  tokenApis: [
    MULTISENDER_API_PARAMS,
    ALCHEMY_API_PARAMS('avax-mainnet.g.alchemy.com'),
    ETHERSCAN_API_PARAMS,
  ],
  blockGasLimit: 30_000_000,
}

export const NETWORK_POLYGON: INetworkConfig = {
  chain: polygon,
  network: {
    chainId: NetworkChainId.polygon,
    name: 'Polygon Mainnet',
    nameShort: 'Polygon',
    icon: 'symbols/polygon',
    pollTime: 15,
    EIP1559: true,
    testnet: false,
    color: '#3099f2',
  },
  currency: {
    name: 'POL',
    symbol: 'POL',
    decimals: 18,
    icon: 'symbols/polygon',
  },
  contracts: {
    multisenderMerkle: {
      address: '0x25f1fdbD4e12C5FBDE4aB4A4F3BE92f19B13997c',
      blockFrom: 62423049,
    },
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  rpc: {
    retryAttempt: 15,
    url: `https://lb.drpc.org/ogrpc?network=polygon&dkey=${DRPC_KEY}`,
  },
  explorer: {
    url: 'https://polygonscan.com',
  },
  tokenApis: [
    MULTISENDER_API_PARAMS,
    BLOCKSCOUT_API_PARAMS('polygon.blockscout.com'),
    ALCHEMY_API_PARAMS('polygon-mainnet.g.alchemy.com'),
    ETHERSCAN_API_PARAMS,
  ],
  blockGasLimit: 30_000_000,
}

export const NETWORK_BASE: INetworkConfig = {
  chain: base,
  network: {
    chainId: NetworkChainId.base,
    name: 'Base',
    nameShort: 'Base',
    icon: 'symbols/base',
    pollTime: 15,
    EIP1559: true,
    testnet: false,
    opStack: true,
    color: '#3099f2',
  },
  currency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    icon: 'symbols/base',
  },
  contracts: {
    multisenderMerkle: {
      address: '0x25f1fdbD4e12C5FBDE4aB4A4F3BE92f19B13997c',
      blockFrom: 20414967,
    },
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  rpc: {
    retryAttempt: 15,
    url: `https://base-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`,
  },
  explorer: {
    url: 'https://basescan.org',
  },
  tokenApis: [
    MULTISENDER_API_PARAMS,
    ALCHEMY_API_PARAMS('base-mainnet.g.alchemy.com'),
    ETHERSCAN_API_PARAMS,
  ],
  blockGasLimit: 30_000_000,
}

export const NETWORK_OPTIMISM: INetworkConfig = {
  chain: optimism,
  network: {
    chainId: NetworkChainId.optimism,
    name: 'Optimism',
    nameShort: 'Optimism',
    icon: 'symbols/optimism',
    pollTime: 15,
    EIP1559: true,
    opStack: true,
    testnet: false,
    color: '#3099f2', // #627EEA
  },
  currency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    icon: 'symbols/optimism',
  },
  contracts: {
    multisenderMerkle: {
      address: '0x25f1fdbD4e12C5FBDE4aB4A4F3BE92f19B13997c',
      blockFrom: 126010508,
    },
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  rpc: {
    retryAttempt: 15,
    url: `https://lb.drpc.org/ogrpc?network=optimism&dkey=${DRPC_KEY}`,
  },
  explorer: {
    url: 'https://optimistic.etherscan.io',
  },
  tokenApis: [
    MULTISENDER_API_PARAMS,
    BLOCKSCOUT_API_PARAMS('optimism.blockscout.com'),
    ALCHEMY_API_PARAMS('opt-mainnet.g.alchemy.com'),
    ETHERSCAN_API_PARAMS,
  ],
  blockGasLimit: 30_000_000,
}

export const NETWORK_CONFIGS = [
  NETWORK_MAINNET,
  NETWORK_BSC,
  NETWORK_ARBITRUM,
  NETWORK_AVALANCHE,
  NETWORK_SEPOLIA,
  NETWORK_POLYGON,
  NETWORK_BASE,
  NETWORK_OPTIMISM,
].reduce((acc, settings) => {
  const { chainId } = settings.network
  acc[chainId] = settings
  return acc
}, {} as Record<NetworkChainId, INetworkConfig>)

/*
// update gasLimit for all networks

const checkGasLimit = () => {
  void Object.values(NETWORK_CONFIGS).reduce(async (acc, { network, rpc }) => {
    await acc
    const { getRpcProviderMemoized } = await import('./utils')
    const provider = getRpcProviderMemoized(rpc.url, network.chainId)

    try {
      const block = await provider.getBlock('latest')
      // eslint-disable-next-line no-console
      console.log({ chainId: network.chainId, gasLimit: block.gasLimit.toNumber() })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ chainId: network.chainId, gasLimit: null })
      // eslint-disable-next-line no-console
      console.error((error as Error)?.message)
    }

    return acc
  }, Promise.resolve(null))
}

checkGasLimit()
*/
