import { computed, ref, watch } from 'vue'

import { PublicClient } from 'viem'
import { useRoute } from 'vue-router'
import { useQuery } from '@tanstack/vue-query'
import { estimateFeesPerGas } from 'viem/actions'

import { FeeData } from '@/types/fees'

import { useAirdropStore, useMultisenderStore } from '@/composable'

import { NETWORK_CONFIGS, NetworkChainId } from '@/services'
import { getRpcProvider } from '@/services/NetworkService/utils'

import {
  ROUTE_AIRDROP,
  ROUTE_AIRDROP_EDIT,
  ROUTE_AIRDROP_DETAILS,
  ROUTE_MULTISENDER_STEP,
} from '@/utils/constants/routes'

export function useNetworkFeeFetcher() {
  const route = useRoute()

  const airdropStore = useAirdropStore()
  const multisendStore = useMultisenderStore()

  const client = ref<PublicClient | undefined>(undefined)

  const isAirdropPage = computed(() => [
    ROUTE_AIRDROP,
    ROUTE_AIRDROP_EDIT,
    ROUTE_AIRDROP_DETAILS,
  ].some((routeName) => routeName === route.name))

  const isMultisendPage = computed(() => route.name === ROUTE_MULTISENDER_STEP)

  const chainConfig = computed(() => {
    const airdropChainId = airdropStore.airdropPublic.value?.chainId
    if (isAirdropPage.value && airdropChainId) {
      return {
        updater: airdropStore.$actions.updateFeeData,
        ...NETWORK_CONFIGS[airdropChainId as NetworkChainId],
      }
    }

    const multisendChainId = multisendStore.chainId.value
    if (isMultisendPage.value && multisendChainId) {
      return {
        updater: multisendStore.$actions.updateFeeData,
        ...NETWORK_CONFIGS[multisendChainId as NetworkChainId],
      }
    }
    return null
  })


  watch(chainConfig, (config) => {
    if (config) {
      client.value = getRpcProvider(config.rpc.url, config.chain.id)
    } else {
      client.value = undefined
    }
  })

  const enabled = computed(() => Boolean(client) && Boolean(chainConfig.value))

  return useQuery({
    enabled,
    queryKey: ['networkFeeFetcher', { chainId: chainConfig.value?.chain.id }],
    queryFn: async (): Promise<FeeData> => {
      if (!client.value || !chainConfig.value) {
        throw new Error('Please init client')
      }

      const { network, chain } = chainConfig.value

      const feeData = await estimateFeesPerGas(client.value, {
        chain,
        type: network.EIP1559 ? 'eip1559' : 'legacy',
      })

      const gasCost = feeData.maxFeePerGas || feeData.gasPrice
      if (feeData.gasPrice) {
        const result = {
          gasCost: String(gasCost),
          gasParams: { gasPrice: String(feeData.gasPrice) },
        }

        void chainConfig.value.updater(result)
        return result
      }

      if (feeData.maxFeePerGas) {
        const result = {
          gasCost: String(gasCost),
          gasParams: {
            maxFeePerGas: String(feeData.maxFeePerGas),
            maxPriorityFeePerGas: String(feeData.maxPriorityFeePerGas),
          },
        }
        void chainConfig.value.updater(result)
        return result
      }

      throw new Error('Undefined Fee Data')
    },
    staleTime: 1_000,
    refetchInterval: 10_000,
  })
}
