import memoize from 'lodash/memoize'
import { http, createPublicClient } from 'viem'

import { NETWORK_CONFIGS, NetworkChainId } from '@/services'


export const isLink = (text: string) => /http(s)?:\/\//.test(text)

export const getRpcProvider = (url?: string, chainId?: number) => {
  if (!chainId) {
    return undefined
  }
  const publicClient = createPublicClient({
    transport: http(url),
    chain: NETWORK_CONFIGS[chainId as NetworkChainId].chain,
  })

  return publicClient
}

export const getStaticRpcProviderMemoized = memoize(getRpcProvider)
