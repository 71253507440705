/* eslint-disable no-underscore-dangle */

import { Hash } from 'viem'
import sortBy from 'lodash/sortBy'

import { GetTokensApis, NetworkService } from '@/services'

import { checkSanctionTokens } from '@/utils'
import { isValidAddress } from '@/utils/crypto'
import { checkIsLink } from '@/utils/checkIsLink'
import { ZERO_ADDRESS } from '@/utils/constants/crypto'

import {
  ITokenData,
  getTokensCovalent,
  getTokensBlockScout,
  getTokensFromAlchemy,
  getTokensFromEthplorer,
  getTokensFromEtherscan,
} from './utils'

import { sentryLogger } from '../SentryLogger'


type ITokenApiSettings = NonNullable<NetworkService['settings']>['tokenApis'][number]

export class WalletTokensService {
  private static _controller?: AbortController

  private _controller?: AbortController

  private get controller() {
    return this._controller
  }

  private set controller(controller) {
    this._controller = controller
    WalletTokensService._controller = controller
  }

  private constructor(
    private chainId: number,
    private ethAccount: Hash,
    private networkService: NetworkService,
  ) {
  }

  public static buildClass(chainId: number, ethAccount: Hash) {
    const networkService = new NetworkService(chainId)
    return new WalletTokensService(chainId, ethAccount, networkService)
  }

  public static cancelLast() {
    WalletTokensService._controller?.abort()
  }

  public cancel() {
    this.controller?.abort()
  }

  public async getTokens() {
    const tokenApis = this.networkService.settings?.tokenApis || []
    this.controller = new AbortController()
    const { signal } = this.controller

    let tokens: ITokenData[] = []

    for (let index = 0; index < tokenApis.length; index += 1) {
      const settings = tokenApis[index]

      try {
        // eslint-disable-next-line @typescript-eslint/await-thenable, no-await-in-loop
        const results = await this.getTokensRequest(settings, signal)

        if (results) {
          tokens = results
          break
        }
      } catch (error) {
        if ((error as Error)?.name === 'AbortError') {
          tokens = []
          break
        } else {
          // eslint-disable-next-line no-console
          console.warn('[App err]: error WalletTokensService#getTokens', error)
          sentryLogger.capture(error)
        }
      }
    }

    return sortBy(tokens, 'symbol')
  }

  private async getTokensRequest(settings: ITokenApiSettings, signal: AbortSignal) {
    const symbol = this.networkService.settings?.currency.symbol || 'ETH'
    const { type, url, query } = settings
    const { ethAccount, chainId } = this

    const isZero = isValidAddress(ethAccount) && ethAccount === ZERO_ADDRESS
    if (isZero) return []

    const options = {
      chainId,
      symbol,
      ethAccount,
      signal,
    }

    let tokens: ITokenData[] = []

    switch (type) {
      case GetTokensApis.ETHPLORER: {
        const path = `${url}/${ethAccount}?${query}`
        tokens = await getTokensFromEthplorer({ ...options, path })
        break
      }

      case GetTokensApis.BLOCKSCOUT: {
        const path = `${url}/?${query}${ethAccount}`
        tokens = await getTokensBlockScout({ ...options, path })
        break
      }

      case GetTokensApis.MULTISENDER: {
        const path = `${url}/${chainId}/address/${ethAccount}`
        tokens = await getTokensCovalent({ ...options, path })
        break
      }

      case GetTokensApis.ETHERSCAN: {
        const path = `${url}?chainId=${chainId}&${query}${ethAccount}&startblock=0&endblock=latest`
        tokens = await getTokensFromEtherscan({ ...options, path })
        break
      }

      case GetTokensApis.ALCHEMY: {
        const path = url
        tokens = await getTokensFromAlchemy({ ...options, path })
        break
      }

      default:
        break
    }

    const sanctionTokens = await checkSanctionTokens(tokens.map((token) => token.address))
    const result = tokens.filter((token) => !sanctionTokens.includes(token.address)
      && !checkIsLink(token.symbol))

    return result
  }
}
