
import { sleep, TxTypes } from '@/utils'
import { ERC20TokenService, MerkleTreeService } from '@/services'
import { IGnosisTx } from '@/store'


const MIN_RETRIES = 5

type TxResponse = {
  'safe': string;
  'to': string;
  'value': string;
  'data': string;
  'operation': number;
  'gasToken': string;
  'safeTxGas': number;
  'baseGas': number;
  'gasPrice': string;
  'refundReceiver': string;
  'nonce': number;
  'executionDate':string;
  'submissionDate':string;
  'modified':string;
  'blockNumber': number;
  'transactionHash': string;
  'safeTxHash': string;
  'proposer': string;
  'executor': string;
  'isExecuted': boolean;
  'isSuccessful': boolean;
  'ethGasPrice': string;
  'maxFeePerGas': string;
  'maxPriorityFeePerGas': string;
  'gasUsed': number;
  'fee': string;
  'origin':string;
  'dataDecoded': null | string;
  'confirmationsRequired': number;
  'confirmations': [
    {
      'owner': string;
      'submissionDate':string;
      'transactionHash': null;
      'signature': string;
      'signatureType': string;
    }
  ];
  'trusted': boolean;
  'signatures': string;
}

export enum TransactionStatus {
  AWAITING_CONFIRMATIONS = 'AWAITING_CONFIRMATIONS',
  AWAITING_EXECUTION = 'AWAITING_EXECUTION',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS'
}

const FAILED_STATUSES = [TransactionStatus.FAILED, TransactionStatus.CANCELLED]

// https://docs.safe.global/api-supported-networks
const API_URL: Record<number, string> = {
  1: 'https://safe-transaction-mainnet.safe.global',
  56: 'https://safe-transaction-bsc.safe.global',
  11155111: 'https://safe-transaction-sepolia.safe.global',
  42161: 'https://safe-transaction-arbitrum.safe.global',
  43114: 'https://safe-transaction-avalanche.safe.global',
  137: 'https://safe-transaction-polygon.safe.global',
  10: 'https://safe-transaction-optimism.safe.global',
  8453: 'https://safe-transaction-base.safe.global',
}

export type Params = {
  hash: string;
  status: TransactionStatus;
  confirmationsCounter?: string;
}

type Callback = (params: Params) => void

export async function getGnosisTx(safeTxHash: string, chainId: number): Promise<TxResponse> {
  const response = await fetch(`${API_URL[chainId]}/api/v1/multisig-transactions/${safeTxHash}`)
  const data = await response.json()
  return data as TxResponse
}

export async function getGnosisNonce(safeAddress: string, chainId: number) {
  const response = await fetch(`${API_URL[chainId]}/api/v1/safes/${safeAddress}`)
  const data = await response.json()
  return data as { nonce: number }
}

export async function checkIsGnosis(safeTxHash: string, chainId: number) {
  try {
    const result = await Promise.race<TxResponse>([
      getGnosisTx(safeTxHash, chainId), new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error('not gnosis tx'))
        }, 3000)
      })])
    return 'safeTxHash' in result
  } catch {
    return false
  }
}

async function fetchGnosisTxFromApi(
  payload: IGnosisTx,
  callback?: Callback,
  fallback?: (payload: IGnosisTx) => Promise<string | undefined>,
): Promise<string | undefined> {
  try {
    const queued = await getGnosisTx(payload.hash, payload.chainId)
    const { nonce } = await getGnosisNonce(payload.multisigAddress, payload.chainId)

    // eslint-disable-next-line no-nested-ternary
    const status = !queued.isExecuted && queued.nonce && nonce && nonce > queued.nonce
      ? TransactionStatus.CANCELLED
      // eslint-disable-next-line no-nested-ternary
      : queued.confirmationsRequired !== queued.confirmations.length
        ? TransactionStatus.AWAITING_CONFIRMATIONS
        // eslint-disable-next-line no-nested-ternary
        : !queued.isExecuted && queued.isSuccessful === null
          ? TransactionStatus.AWAITING_EXECUTION
          : queued.isSuccessful
            ? TransactionStatus.SUCCESS
            : TransactionStatus.FAILED

    if (typeof callback === 'function') {
      let confirmationsCounter: undefined | string

      if (queued.confirmationsRequired && queued.confirmations) {
        const { confirmations, confirmationsRequired } = queued
        confirmationsCounter = confirmations && confirmationsRequired ? `${confirmations.length} / ${confirmationsRequired}` : undefined
      }

      callback({
        status,
        hash: payload.hash,
        confirmationsCounter,
      })
    }

    if (FAILED_STATUSES.includes(status)) {
      throw new Error(`The status "${status}" of a Gnosis Safe transaction (${payload.hash}) has been detected`)
    }

    if (!queued.isExecuted) {
      const result = await fallback?.(payload)
      return result
    }

    return queued.transactionHash
  } catch (err) {
    const resp = await fallback?.(payload)
    if (resp) {
      return resp
    }
    throw err
  }
}

async function handleGnosisAirdropEvent(payload: IGnosisTx) {
  if (!payload.details.airdropName) {
    return undefined
  }
  const treeService = new MerkleTreeService(payload.chainId)
  const event = await treeService.getAirdropEvent(
    payload.details.airdropName,
    payload.multisigAddress,
    payload.blockNumber,
  )

  return event
}

async function handleGnosisClaimEvent(payload: IGnosisTx) {
  if (!payload.details.airdropName) {
    return undefined
  }
  const treeService = new MerkleTreeService(payload.chainId)
  const event = await treeService.getClaimEvent(
    payload.details.airdropName,
    payload.multisigAddress,
    payload.blockNumber,
  )

  return event
}

async function handleGnosisCancelAirdropEvent(payload: IGnosisTx) {
  if (!payload.details.airdropName) {
    return undefined
  }
  const treeService = new MerkleTreeService(payload.chainId)
  const event = await treeService.getCancelAirdropEvent(
    payload.details.airdropName,
    payload.blockNumber,
  )

  return event
}

async function handleGnosisApproveEvent(payload: IGnosisTx) {
  const contract = MerkleTreeService.getContract(payload.chainId)
  if (!contract) {
    return undefined
  }
  const tokenService = ERC20TokenService.buildClass(payload.token, payload.chainId)
  const event = await tokenService.getApproveEvent(payload.multisigAddress, contract.address, payload.blockNumber)

  return event
}

async function handleGnosisTxAlternative(payload: IGnosisTx): Promise<string | undefined> {
  let transactionHash: string | undefined
  switch (payload.details.type) {
    case TxTypes.createAirdrop:
      // eslint-disable-next-line no-case-declarations
      const airdropEvent = await handleGnosisAirdropEvent(payload)
      transactionHash = airdropEvent?.transactionHash
      break
    case TxTypes.approveToken:
      // eslint-disable-next-line no-case-declarations
      const approveAirdropEvent = await handleGnosisApproveEvent(payload)
      transactionHash = approveAirdropEvent?.transactionHash
      break
    case TxTypes.cancelAirdrop:
      // eslint-disable-next-line no-case-declarations
      const cancelAirdropEvent = await handleGnosisCancelAirdropEvent(payload)
      transactionHash = cancelAirdropEvent?.transactionHash
      break
    case TxTypes.claim:
      // eslint-disable-next-line no-case-declarations
      const claimEvent = await handleGnosisClaimEvent(payload)
      transactionHash = claimEvent?.transactionHash
      break
    default:
      break
  }

  return transactionHash
}

export async function waitSafeTx(
  payload: IGnosisTx,
  callback?: Callback,
  retry = 0,
): Promise<string> {
  try {
    const gnosisResp = await fetchGnosisTxFromApi(payload, callback, handleGnosisTxAlternative)

    if (!gnosisResp) {
      await sleep(10_000)
      return waitSafeTx(payload, callback, retry)
    }

    return gnosisResp
  } catch (err) {
    if (retry < MIN_RETRIES) {
      await sleep(10_000)
      return waitSafeTx(payload, callback, retry + 1)
    }
    throw err
  }
}
