import { computed } from 'vue'

import { useRoute } from 'vue-router'
import { useQuery } from '@tanstack/vue-query'
import { estimateContractL1Fee } from 'viem/op-stack'

import { useMultisenderStore, useWalletStore } from '@/composable'

import { BI_ZERO } from '@/utils/constants/numbers'
import { NETWORK_CONFIGS, NetworkChainId, NetworkService } from '@/services'

import MerkleTree from '@/abi/MerkleProofAirdrop'

export function useCreateMultisendL1Fetcher() {
  const route = useRoute()

  const walletStore = useWalletStore()
  const multisendStore = useMultisenderStore()

  const chainConfig = computed(() => {
    const chainId = multisendStore.chainId.value
    return chainId ? NETWORK_CONFIGS[chainId as NetworkChainId] : null
  })

  const enabled = computed(() => Boolean(
    route.path.includes('distribution')
        && chainConfig.value?.network.opStack
        && multisendStore.airdropPrivate.value
        && multisendStore.currentFee.value,
  ))

  return useQuery({
    enabled,
    queryKey: ['createMultisendL1Fetcher', {
      chainId: chainConfig.value?.chain.id,
      ethAccount: walletStore.ethAccount.value,
      currentFee: multisendStore.currentFee.value,
      airdropPrivate: multisendStore.airdropPrivate.value,
    }],
    queryFn: async (): Promise<string> => {
      const ethAccount = walletStore.ethAccount.value
      const currentFee = multisendStore.currentFee.value
      const airdropPrivate = multisendStore.airdropPrivate.value

      if (!currentFee || !airdropPrivate || !ethAccount) {
        throw new Error('Not all parameters was provided')
      }

      if (!chainConfig.value) {
        throw new Error('Please init client')
      }

      const networkService = new NetworkService(chainConfig.value.chain.id)

      if (!networkService.rpcProvider) {
        throw new Error('Undefined RPC provider')
      }

      const contracts = networkService.settings?.contracts

      if (!contracts?.multisenderMerkle.address) {
        throw new Error('Please init client')
      }

      const args = [
        airdropPrivate.publicName,
        airdropPrivate.treeRoot,
        airdropPrivate.token.address,
        BigInt(airdropPrivate.recipientsAmount),
      ] as const

      const l1Fee = await estimateContractL1Fee(networkService.rpcProvider, {
        args,
        abi: MerkleTree,
        account: ethAccount,
        value: BigInt(currentFee),
        functionName: 'createAirdrop',
        address: contracts?.multisenderMerkle.address,
      }).catch(() => BI_ZERO)


      void multisendStore.$actions.updateL1Fee(l1Fee.toString())
      return l1Fee.toString()
    },
    staleTime: 1_000,
    refetchInterval: 10_000,
  })
}
