// eslint-disable-next-line object-curly-newline
import { memoizeAsync } from 'utils-decorators'

import { multicall } from 'viem/actions'
import { estimateL1Fee } from 'viem/op-stack'
import { Hash, getContract, encodeFunctionData } from 'viem'

import { FeeData } from '@/types/fees'

import { NetworkService } from '@/services'

import { BI_ZERO } from '@/utils/constants/numbers'
import { fetchLogsInChunks } from '@/utils/getContractLogs'
import { formatWeiHuman, getGasLimitWithCheckBalance } from '@/utils'

import ERC20Abi from '@/abi/ERC20'

export enum ApproveTokenTypes {
  default = 'default',
  revoke = 'revoke',
  unknown = 'unknown',
}

const instances = new Map<string, ERC20TokenService>()

export class ERC20TokenService {
  public symbol = ''

  public decimals = 18

  public balance: string | null = null

  public allowanceForMultisender: string | null = null

  public get balanceHuman() {
    const val = this.balance
    return val ? formatWeiHuman(val, this.decimals) : ''
  }

  public get allowanceForMultisenderHuman() {
    const val = this.allowanceForMultisender
    return val ? formatWeiHuman(val, this.decimals) : ''
  }

  public get contract() {
    const client = new NetworkService(this.chainId).rpcProvider

    if (!client) {
      return undefined
    }

    return getContract({ address: this.address, abi: ERC20Abi, client })
  }

  public static buildClass(
    address: Hash,
    chainId: number,
    ethAccount?: Hash | null,
  ) {
    const key = [address.toLowerCase(), chainId].join('-')
    let instance = instances.get(key)

    if (!instance) {
      instance = new ERC20TokenService(address, chainId, ethAccount)
      instances.set(key, instance)
    }

    instance.ethAccount = ethAccount

    return instance
  }

  private constructor(
    public address: Hash,
    public chainId: number,
    private ethAccount?: Hash | null,
  ) {
  }

  public async updateData() {
    const promises = [
      this.updateMeta(),
      this.updateAllowanceForMultisender(),
      this.updateBalance(),
    ] as const

    return Promise.all(promises)
  }

  public async updateMeta() {
    if (!this.contract) {
      throw new Error('Please init contract')
    }
    [this.symbol, this.decimals] = await this.getMeta(this.contract.address, this.chainId)
  }

  @memoizeAsync()
  public async getMeta(address: Hash, chainId: number) {
    try {
      const client = new NetworkService(this.chainId).rpcProvider
      if (!client) {
        throw new Error('Please init provider')
      }

      const [decimals, symbol] = await multicall(client, {
        contracts: [
          {
            address,
            abi: ERC20Abi,
            functionName: 'decimals',
          },
          {
            address,
            abi: ERC20Abi,
            functionName: 'symbol',
          },
        ],
        allowFailure: false,
      })

      return [symbol || '', decimals] as const
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('[App err]: error ERC20TokenService#getMeta', { chainId, address }, error)
      throw error
    }
  }

  public async updateAllowanceForMultisender() {
    const networkService = new NetworkService(this.chainId)
    const address = networkService.settings?.contracts.multisenderMerkle.address

    if (!this.ethAccount || !address || !this.contract) {
      return null
    }

    const allowance = await this.contract.read.allowance([this.ethAccount, address])
    this.allowanceForMultisender = allowance.toString()
    return this.allowanceForMultisender
  }

  public async updateBalance() {
    if (!this.ethAccount || !this.contract) {
      return null
    }

    const balance = await this.contract.read.balanceOf([this.ethAccount])

    this.balance = balance.toString()
    return this.balance
  }

  public async getApproveForMultisenderType() {
    const networkService = new NetworkService(this.chainId)

    if (!networkService.settings) {
      return null
    }

    const { address } = networkService.settings.contracts.multisenderMerkle

    if (!this.ethAccount || !address || !this.contract) {
      return null
    }

    const overrides = { account: this.ethAccount }

    try {
      await this.contract.estimateGas.approve([address, 2n], overrides)
      return ApproveTokenTypes.default
    } catch {
      //
    }

    try {
      await this.contract.estimateGas.approve([address, 0n], overrides)
      return ApproveTokenTypes.revoke
    } catch {
      //
    }

    return ApproveTokenTypes.unknown
  }

  public async approveForMultisender(amount: string | bigint, feeData: FeeData, isInjectedWallet:boolean) {
    const { rpcProvider: client, settings } = new NetworkService(this.chainId)

    if (!settings || !client) {
      return null
    }

    const { address } = settings.contracts.multisenderMerkle

    if (!this.ethAccount || !address || !this.contract) {
      return null
    }

    const args = [address, BigInt(amount)] as const

    const overrides = { account: this.ethAccount }

    const data = encodeFunctionData({
      args,
      abi: ERC20Abi,
      functionName: 'approve',
    })

    const { gasLimit, gasParams } = await getGasLimitWithCheckBalance(
      this.contract.estimateGas.approve(args, overrides),
      settings.network.opStack ? estimateL1Fee(client, {
        data,
        ...overrides,
        to: this.contract.address,
      }) : Promise.resolve(BI_ZERO),
      feeData,
      client,
      this.ethAccount,
      isInjectedWallet,
    )

    return {
      ...gasParams,
      data,
      gas: gasLimit,
      to: this.contract.address,
    }
  }

  public async getApproveEvent(owner: Hash, spender: Hash, blockNumber: number) {
    if (!this.contract) {
      throw new Error('Please init contract')
    }

    const [log] = await fetchLogsInChunks({
      chainId: this.chainId,
      fromBlock: BigInt(blockNumber),
      getEvents: (from: bigint, to: bigint) => {
        if (!this.contract) {
          throw new Error('Please init contract')
        }
        return this.contract.getEvents.Approval({ owner, spender }, {
          toBlock: to,
          fromBlock: from,
        })
      },
    })

    return log
  }
}
